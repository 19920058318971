.bm-burger-button {
  float: right;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  position: relative;
  margin-top: 10px;
  margin-right: 12px;
  background-color: #098E93;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.04);
}

.bm-cross-button {
  height: 60px !important;
  width: 60px !important;
  margin-top: 36px;
  margin-right: 36px;
}

.bm-item-list {
  text-align: center;
  padding: 124px 0px;
  height: 100px !important;
}

.menu-item {
  font-size: 18px;
  padding: 18px 0px;
}

.bm-overlay {
  z-index: 1010 !important;
  background: rgba(255,255,255,1) !important;
}
